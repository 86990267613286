<template>
    <div class="sm:px-4 px-0">
       
        <!-- <div class="sm:hidden ">
            <IconAmburger @click="signMenu = !signMenu" />
            <div style="right: 20px" class="bg-white flex flex-col px-3 py-2 absolute shadow-md" v-if="signMenu">
                <router-link to="/register">
                {{ $t("sign.register") }}
                </router-link>
                <router-link to="/signsuccess">
                    {{ $t("sign.login") }}
                </router-link>
            </div>
        </div> -->
        <div class="sm:hidden flex py-2 px-1">
            <div class="flex">
                <router-link to="/"><IconkkiapayCoin class=""/></router-link>
            </div>
            <!-- <IconAmburger /> -->
            <div class="flex justify-end ml-auto">
                <router-link to="/recharges/register" class="mr-2">
                    <base-button class="px-2 text-sm" :text="$t('sign.register')" color="primary-dark" outline />
                </router-link>
                <router-link to="/recharges/signsuccess">
                    <base-button class="px-3 text-sm" :text="$t('sign.login')" color="primary-dark" />
                </router-link>
            </div>
        </div>
        <div class="hidden sm:flex">
            <div class="flex">
             <router-link to="/"><Iconkkiapay class=""/></router-link>
            </div>
            <div class="flex ml-auto">
             <router-link to="/recharges/register"><button class="border text-bgk-4 border-bgk-4 h-search sm:h-minput  hover:border-tex-6 hover:text-tex-6 active:border-tex-6 py-1 sm:py-2 px-4 sm:px-8 text-sm sm:text-base rounded focus:outline-none">
                {{ $t("sign.register") }}
            </button></router-link>
             <router-link to="/recharges/signsuccess"><button class="ml-4 text-white h-search sm:h-minput bg-bgk-4 hover:bg-tex-6  active:border-tex-6 py-1 sm:py-2 px-4 sm:px-8 text-sm sm:text-base rounded focus:outline-none">
                {{ $t("sign.login") }}
            </button> </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Iconkkiapay from '../../assets/svg/Group156.svg?inline';
import IconkkiapayCoin from '../../assets/svg/kkiapay-coin.svg?inline';
// import IconAmburger from '../../assets/svg/amburger.svg?inline';
import BaseButton from '../BaseButton.vue';

export default {

    data() {
        return {
            
            signMenu: false,
        }
    },
    
    components:{
        Iconkkiapay,
        IconkkiapayCoin,
        // IconAmburger,
        BaseButton,
    },
}
</script>

<style scoped>
.justify-end {
    justify-content: flex-end;
}
</style>